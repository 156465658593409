<input type="file" id="file_input" (change)="uploadFile($event)">

<label for="file_input" class="primary">Attach Files</label>

<table *ngIf="attachments.length > 0; else noAttachments">
    <thead>
        <tr>
            <th>Name</th>
            <th>Actions</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let attachment of attachments">
            <td>{{attachment}}</td>
            <td>
                <a aria-roledescription="button" title="Remove Attachment" (click)="removeAttachment(attachment)">
                    <img src="assets/images/xmark.svg" style="width: 18px; height: auto;">
                </a>
            </td>
        </tr>
    </tbody>
</table>

<ng-template #noAttachments>
    <div style="margin-bottom: 24px;">
        <em>No files attached</em>
    </div>
</ng-template>

<div *ngIf="error" class="error" style="font-weight: 600; margin-bottom: 24px;">One or more attachments are required.</div>