import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { Router, RouterModule } from '@angular/router';
import { PasswordChange } from '../../models/password-change.model';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'trackomatic-change-password',
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDividerModule,
        MatCardModule,
        MatSnackBarModule,
        ReactiveFormsModule,
        RouterModule
    ],
    templateUrl: './change-password.component.html',
    styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {
    changePasswordForm = new FormGroup({
        password: new FormControl('', [Validators.required]),
        newPassword: new FormControl('', [Validators.required]),
        verifyPassword: new FormControl('', [Validators.required])
    });

    constructor(private authService: AuthService, private router: Router, private snackBar: MatSnackBar) { }

    cancel() {
        history.back();
    }

    changePassword() {
        this.authService.changePassword(this.changePasswordForm.value as PasswordChange)
            .subscribe(
                _ => {
                    this.snackBar.open("Your password has been changed.", "OK");
                    this.router.navigate(['']);
                },
                _ => { alert('bad'); }
            );
    }
}
