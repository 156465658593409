<div *ngIf="question">
    <div style="display: flex;">
        <div class="formfield" style="flex: 1; margin-right: 24px;" [ngClass]="{'error': state == 'error'}">
            <input [id]="answerSet.id + '_' + question.id" type="text" placeholder="" class="signature" [(ngModel)]="value"
                [title]="question.label" (blur)="saveAnswer()" (focus)="setInputState('clean')">
            <label [htmlFor]="answerSet.id + '_' + question.id" class="required">{{question.label}}</label>
        </div>

        <div class="formfield">
            <input type="text" placeholder="" class="signature" [value]="date" title="Today's Date" disabled>
            <label>Date</label>
        </div>
    </div>

    <div *ngIf="state == 'error'" class="error error-message">Your signature is required.</div>
</div>