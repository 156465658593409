import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AuthService } from 'auth';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { routes } from '../app.routes';
import { Form, FormSet } from '../shared/form.model';
import { FormService } from '../shared/form.service';
import { Branding } from '../shared/organization.model';
import { OrganizationService } from '../shared/organization.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { ApplicationService } from '../shared/application.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrl: './layout.component.scss',
    standalone: true,
    imports: [
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatIconModule,
        MatMenuModule,
        MatDividerModule,
        MatExpansionModule,
        AsyncPipe,
        RouterLink,
        RouterLinkActive,
        CommonModule
    ]
})
export class LayoutComponent implements OnInit {
    private breakpointObserver = inject(BreakpointObserver);
    rootRoutes = routes.filter(r => r.path && (r.data == undefined || r.data['hasLayout'] !== false));

    idTokenClaims?: Map<string, unknown>;

    formSets?: Observable<FormSet[]>;
    currentForm?: Form;
    logo?: string;
    isSubmitted?: boolean;

    constructor(private authService: AuthService, private formService: FormService, private organizationService: OrganizationService, private applicationService: ApplicationService) { }

    ngOnInit() {
        this.applicationService.getUserApplication().subscribe(application => {
            this.isSubmitted = application != null && application.applicationStatusId > 1;
        });

        this.authService.getIdTokenClaims().subscribe(claims => {
            this.idTokenClaims = claims
        });

        this.formService.formResponse.subscribe(formResponse => this.currentForm = formResponse?.form);
        this.formSets = this.formService.listForms();

        this.organizationService.getBranding().subscribe((response: Branding) => {
            this.logo = response.logoUrl;
            const root = document.querySelector(":root") as HTMLElement;
            root.style.setProperty('--primary-color', response.primaryColor);
            root.style.setProperty('--secondary-color', response.secondaryColor);
        });
    }

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    setForm(id: number) {
        this.formService.setForm(id)
    }

    isParent(forms: Form[]): boolean {
        return forms.some(f => f.id == this.currentForm?.id);
    }
}
