import { CommonModule } from "@angular/common";
import { Component, forwardRef, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MetadataPipe } from "../../pipes/metadata.pipe";
import { QuestionSetComponent } from "../question-set.component";
import { BaseFieldComponent } from "./base-field.component";

@Component({
    selector: 'app-yes-no-field',
    templateUrl: './yes-no-field.component.html',
    styleUrls: ['./yes-no-field.component.css'],
    standalone: true,
    imports: [CommonModule, FormsModule, MetadataPipe, forwardRef(() => QuestionSetComponent)]
})
export class YesNoFieldComponent extends BaseFieldComponent implements OnInit {
    questionSetId: number | undefined;
    metadataPipe = new MetadataPipe();

    override ngOnInit(): void {
        super.ngOnInit();

        const questionSetIfYesId = this.metadataPipe.transform(this.question, "questionSetIfYes");
        if (questionSetIfYesId && this.value == "yes") {
            this.questionSetId = parseInt(questionSetIfYesId);
        }

        const questionSetIfNoId = this.metadataPipe.transform(this.question, "questionSetIfNo");
        if (questionSetIfNoId && this.value == "no") {
            this.questionSetId = parseInt(questionSetIfNoId);
        }
    }

    displayQuestionSet() {
        const questionSetId = this.metadataPipe.transform(this.question!, this.value == "yes" ? "questionSetIfYes" : "questionSetIfNo");
        if (questionSetId) {
            this.questionSetId = parseInt(questionSetId);
        }

        else {
            this.questionSetId = undefined;
        }
    }
}