import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, tap } from "rxjs";
import { Application } from "./application.model";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {
    application: BehaviorSubject<Application | null> = new BehaviorSubject<Application | null>(null);

    constructor(private http: HttpClient) { }

    getUserApplication() {
        return this.http.get<Application>(`${environment.apiConfig.url}/applications/me`).pipe(
            tap(application => {
                if (application) {
                    this.application.next(application);
                }
            })
        );
    }

    submitApplication() {
        return this.http.post<Application>(`${environment.apiConfig.url}/applications/me`, {}).pipe(
            tap(application => {
                if (application) {
                    this.application.next(application);
                }
            })
        );
    }
}