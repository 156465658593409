<div *ngIf="question" [ngClass]="{'error': state == 'error'}">
    <div style="display: flex;">
        <div class="formfield" style="flex: 1; margin-right: 24px;">
            <input type="date" [id]="answerSet.id + '_' + question.id" placeholder="(MM/DD/YYYY)" [(ngModel)]="value" (blur)="saveAnswer()"  (focus)="setInputState('clean')"
                [title]="question.label">
            <label [htmlFor]="answerSet.id + '_' + question.id" [ngClass]="{'required': question.required}">{{question.label}}</label>
            <!-- <button type="button" title="Open date picker">
                <img style="width: 24px; height: 24px;" src="assets/images/calendar.svg">
            </button> -->
        </div>
    </div>
    <div *ngIf="state == 'error'" class="error-message">{{question.label}} is a required field.</div>
</div>