// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    msalConfig: {
        auth: {
            //   clientId: 'ENTER_CLIENT_ID',
            //   authority: 'ENTER_AUTHORITY'
            clientId: '3db3179f-3417-4cec-8fc6-cbed5f9d1b9d',
            authority: 'https://myriskaware.b2clogin.com/myriskaware.onmicrosoft.com/B2C_1_ATS_Applicant/',
            authorityDomain: 'myriskaware.b2clogin.com'
        }
    },
    apiConfig: {
        scopes: ['openid', 'https://myriskaware.onmicrosoft.com/ats/applicant.all'],
        url: 'http://localhost:5034'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
