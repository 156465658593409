import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { environment } from "../../environments/environment";
import { Branding, Organization } from "./organization.model";

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {
    organization: ReplaySubject<Organization> = new ReplaySubject<Organization>(1);

    constructor(private http: HttpClient) { }

    getForUser(): Observable<Organization> {
        return this.http.get<Organization>(`${environment.apiConfig.url}/organizations/me`);
    }

    getBranding(): Observable<Branding> {
        return this.http.get<Branding>(`${environment.apiConfig.url}/organizations/branding`);
    }
}