import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { Signup } from '../../models/signup.model';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'trackomatic-signup',
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDividerModule,
        MatCardModule,
        ReactiveFormsModule,
        RouterModule
    ],
    templateUrl: './signup.component.html',
    styleUrl: './signup.component.scss'
})
export class SignupComponent {
    signupForm = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        emailAddress: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required]),
        verifyPassword: new FormControl('', [Validators.required])
    });

    constructor(private authService: AuthService, private router: Router) { }

    signup() {
        this.authService.signup(this.signupForm.value as Signup)
            .subscribe(
                _ => this.router.navigate(['']),
                _ => { alert('bad'); }
            );
    }
}
