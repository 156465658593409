import { CommonModule } from "@angular/common";
import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MetadataPipe } from "../pipes/metadata.pipe";
import { FormService } from "../shared/form.service";
import { QuestionSet } from "../shared/question.model";
import { QuestionService } from "../shared/question.service";
import { RawHtmlPipe } from "../shared/rawhtml.pipe";
import { QuestionFactoryComponent } from './question-factory.component';
import { switchMap } from "rxjs";

@Component({
    selector: 'app-question-set',
    templateUrl: './question-set.component.html',
    styleUrls: ['./question-set.component.css'],
    standalone: true,
    imports: [CommonModule, FormsModule, RawHtmlPipe, forwardRef(() => QuestionFactoryComponent), MetadataPipe],
    providers: [QuestionService]
})
export class QuestionSetComponent implements OnInit {
    @Input() questionSet: QuestionSet | undefined;
    @Input() questionSetId: number | undefined;

    constructor(private formService: FormService) { }

    ngOnInit() {
        if (this.questionSet == undefined && this.questionSetId) {
            this.formService.getQuestionSetById(this.questionSetId).subscribe(response => {
                this.questionSet = response;
            });
        }
    }

    addAnswerSet(questionSetId: number) {
        this.formService.createAnswerSet(questionSetId).pipe(
            switchMap(() => {
                return this.formService.getQuestionSetById(this.questionSet!.id)
            })
        ).subscribe(response => {
            this.questionSet = response;
        });
    }

    removeAnswerSet(id: number) {
        this.formService.deleteAnswerSet(id).pipe(
            switchMap(() => {
                return this.formService.getQuestionSetById(this.questionSet!.id)
            })
        ).subscribe(response => {
            this.questionSet = response;
        });
    }
}