<div *ngIf="question" [ngClass]="{'error': state == 'error'}">
    <div style="display: flex;">
        <div class="formfield" style="flex: 1; margin-right: 24px;">
            <select [id]="answerSet.id + '_' + question.id" [(ngModel)]="value" (blur)="saveAnswer()" (focus)="setInputState('clean')" [title]="question.label">
                <option value=""></option>
                <option *ngFor="let option of question | metadata:'options' | split:','" [value]="option">{{option}}</option>
            </select>
            <label [htmlFor]="answerSet.id + '_' + question.id" [ngClass]="{'required': question.required}">{{question.label}}</label>
        </div>
    </div>
    <div *ngIf="state == 'error'" class="error-message">{{question.label}} is a required field.</div>
</div>