import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LayoutComponent } from './layout/layout.component';
@Component({
    selector: 'app-root',
    imports: [RouterOutlet, LayoutComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
    hasLayout?: boolean;

    constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.hasLayout = this.activatedRoute.firstChild?.snapshot.data['hasLayout'];
            });
    }
}