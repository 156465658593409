import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { last, map } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const userRoleGuard: CanActivateFn = (route, state) => {
    const authService = inject(AuthService);
    const router =  inject(Router);

    return authService.getTokens().pipe(
        last(),
        map(tokens => {
            if (!tokens) {
                router.navigate(['auth', 'login']);
                return false;
            }

            const authClaims = authService.decode(tokens.accessToken);
            const roleClaim = authClaims.get('role') as string;

            for (const role of route.data['roles']) {
                if (roleClaim.indexOf(role) > -1) {
                    return true;
                }
            }
            return false;
        })
    );
}