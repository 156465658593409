import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable, ReplaySubject, take, tap } from "rxjs";
import { environment } from "../../environments/environment";
import { Form, FormResponse, FormSet } from "./form.model";
import { AnswerSet, Question, QuestionSet } from "./question.model";

@Injectable({
    providedIn: 'root'
})
export class FormService {
    constructor(private http: HttpClient) { }

    formResponse: BehaviorSubject<FormResponse | null> = new BehaviorSubject<FormResponse | null>(null);
    shouldValidate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    invalidQuestions: BehaviorSubject<Question[]> = new BehaviorSubject<Question[]>([]);

    setForm(id: number) {
        this.getById(id).subscribe(form => {
            const answerSetRequests: Observable<AnswerSet>[] = [];

            if (form.form.questionSets.length > 0) {
                form.form.questionSets.forEach(questionSet => {
                    if (questionSet.answerSets.length == 0) {
                        answerSetRequests.push(
                            this.http.post<AnswerSet>(`${environment.apiConfig.url}/answers/sets/`, {
                                questionSetId: questionSet.id
                            }));
                    }
                });

                if (answerSetRequests.length > 0) {
                    forkJoin(answerSetRequests).subscribe((data: AnswerSet[]) => {
                        form.form.questionSets.forEach(questionSet => {
                            questionSet.answerSets = data.filter(d => d.questionSetId == questionSet.id)
                        });

                        this.formResponse.next(form);
                        this.shouldValidate.next(false);
                    });
                }

                else {
                    this.formResponse.next(form);
                    this.shouldValidate.next(false);
                }
            }

            else {
                this.formResponse.next(form);
                this.shouldValidate.next(false);
            }
        });
    }

    getById(id: number): Observable<FormResponse> {
        return this.http.get<FormResponse>(`${environment.apiConfig.url}/forms/${id}?`);
    }

    getQuestionSetById(id: number): Observable<QuestionSet> {
        return this.http.get<QuestionSet>(`${environment.apiConfig.url}/questions/sets/${id}?`);
    }

    createAnswerSet(id: number): Observable<AnswerSet> {
        return this.http.post<AnswerSet>(`${environment.apiConfig.url}/answers/sets/`, {
            questionSetId: id
        });
    }

    deleteAnswerSet(id: number): Observable<unknown> {
        return this.http.delete(`${environment.apiConfig.url}/answers/sets/${id}`);
    }

    nextForm() {
        console.log([this.formResponse.value, this.formResponse.value?.nextId]);
        if (this.formResponse.value && this.formResponse.value.nextId) {
            return this.setForm(this.formResponse.value.nextId)
        }
    }

    previousForm() {
        if (this.formResponse.value && this.formResponse.value.previousId) {
            return this.setForm(this.formResponse.value.previousId)
        }
    }

    currentForm(): Form | null {
        return this.formResponse.value
            ? this.formResponse.value.form
            : null;
    }

    listForms(): Observable<FormSet[]> {
        return this.http.get<FormSet[]>(`${environment.apiConfig.url}/forms`);
    }
}