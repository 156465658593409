import { ChangeDetectorRef, Component, EventEmitter, Input, input, OnDestroy, OnInit, Output } from "@angular/core";
import { Answer, AnswerSet, Question } from "../../shared/question.model";
import { QuestionService } from "../../shared/question.service";
import { Subscription } from "rxjs";
import { FormService } from "../../shared/form.service";

type State = "clean" | "valid" | "error";

@Component({
    template: ''
})
export class BaseFieldComponent implements OnInit, OnDestroy {
    private subscription: Subscription = null!;

    @Input() question: Question = null!;
    @Input() answerSet: AnswerSet = null!;
    inputStateChanged: EventEmitter<string> = new EventEmitter<string>();

    state: State = "clean";
    value = "";

    constructor(protected questionService: QuestionService, protected formService: FormService) { }

    ngOnInit() {
        const answer = this.answerSet.answers?.find(answer => answer.questionId == this.question.id);
        if (answer) {
            this.value = answer.value;
        }

        this.subscription = this.formService.shouldValidate.subscribe(shouldValidate => {
            if (shouldValidate) {
                const question = this.question!;
                let invalidQuestions = this.formService.invalidQuestions.value.filter(q => q.id != question.id);

                if (!this.isValidField()) {
                    invalidQuestions.push(question);
                }

                this.formService.invalidQuestions.next(invalidQuestions);
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.formService.invalidQuestions.next([]);
    }

    setInputState(state: State) {
        this.state = state;
    }

    isValidField(): boolean {
        if (this.question?.required && !this.value) {
            this.setInputState('error');
            return false;
            // this.changeDetectorRef.detectChanges();
        }

        else {
            return true;
        }
    }

    saveAnswer() {
        if (this.isValidField()) {
            const answer: Answer = {
                questionId: this.question.id,
                answerSetId: this.answerSet.id,
                value: this.value
            }

            const subscription: Subscription = this.questionService.saveAnswer(answer).subscribe(() => subscription.unsubscribe());
        }
    }
}