import { Component } from "@angular/core";
import { MatCardModule } from "@angular/material/card";

@Component({
    selector: 'app-home',
    templateUrl: './submitted.component.html',
    standalone: true,
    imports: [MatCardModule]
})
export class SubmittedComponent {
    
}