<div class="layout-container">
    <img src="https://riskaware.com/wp-content/themes/rasite/images/logo.png" title="RiskAware, LLC"
        alt="RiskAware, LLC">
    <mat-card>
        <mat-card-content>
            <h3>Sign In</h3>
            <p>with your RiskAware account.</p>
            <form [formGroup]="loginForm" (ngSubmit)="login()">
                <mat-form-field appearance="outline">
                    <mat-label>Email Address</mat-label>
                    <input matInput formControlName="emailAddress">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" type="password">
                </mat-form-field>

                <button type="submit" mat-flat-button>Login</button>
            </form>

            @if (authConfig.allowSignup === true) {
            <mat-divider></mat-divider>

            <div>
                <strong>No account?</strong>
                <a [routerLink]="['/auth', 'signup']">Sign Up</a>
            </div>
            }
        </mat-card-content>
    </mat-card>
</div>