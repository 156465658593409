import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { FormRendererComponent } from '../questions/form-renderer.component';
import { ApplicationService } from '../shared/application.service';
import { FormResponse } from '../shared/form.model';
import { FormService } from '../shared/form.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    standalone: true,
    imports: [CommonModule, FormsModule, MatCardModule, FormRendererComponent]
})
export class HomeComponent implements OnInit {
    formResponse?: FormResponse;
    constructor(private formService: FormService, private applicationService: ApplicationService, private router: Router, private route: ActivatedRoute) { }

    set id(id: number) {
        this.formService.setForm(id);
    }

    ngOnInit() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.id = Number(this.route.snapshot.paramMap.get('id'));
            });

        this.id = Number(this.route.snapshot.paramMap.get('id'));

        this.formService.formResponse.subscribe(formResponse => {
            this.formResponse = formResponse!
        });
    }

    nextForm() {
        if (!this.formResponse?.nextId) {
            this.applicationService.submitApplication().subscribe(() => {
                
            });
        }

        else {
            this.formService.shouldValidate.next(true);

            if (this.formService.invalidQuestions.value.length === 0) {
                this.router.navigate(['form', this.formResponse?.nextId])
            }
        }
    }

    previousForm() {
        this.router.navigate(['form', this.formResponse?.previousId])
    }
}
