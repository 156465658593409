<div class="container">
    <mat-toolbar>
        <div>
            @if (isHandset$ | async) {
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            }
            <img *ngIf="logo" [src]="logo" class="logo">
        </div>

        <div>
            @if (idTokenClaims) {
            <button class="user-menu-trigger" mat-button [matMenuTriggerFor]="userMenu">
                <mat-icon>account_circle</mat-icon>
                {{ idTokenClaims.get('given_name') }}
                {{ idTokenClaims.get('family_name') }}
            </button>
            <mat-menu #userMenu="matMenu" xPosition="before">

                <a [routerLink]="['auth', 'logout']" mat-menu-item>
                    <mat-icon>logout</mat-icon> Log Out
                </a>
            </mat-menu>
            }
        </div>
    </mat-toolbar>

    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav #drawer class="sidenav" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
            [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
            <nav *ngIf="isSubmitted !== true">
                <mat-accordion multi>
                    <mat-expansion-panel *ngFor="let formSet of formSets | async" togglePosition="before"
                        class="mat-elevation-z0" [expanded]="isParent(formSet.forms)">
                        <mat-expansion-panel-header [ngClass]="{'active-section': isParent(formSet.forms)}">
                            <mat-panel-title>{{ formSet.name }}</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="nav-section">
                            <a *ngFor="let form of formSet.forms" [routerLink]="['form', form.id]"
                                #link="routerLinkActive" class="form-link" routerLinkActive="active-form">
                                {{ form.shortName}}
                            </a>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </nav>
        </mat-sidenav>
        <mat-sidenav-content>
            <ng-content></ng-content>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>