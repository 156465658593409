import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ApplicationService } from '../app/shared/application.service';
import { last, map } from 'rxjs';

export const isIncompleteGuard: CanActivateFn = () => {
    const applicationService = inject(ApplicationService);
    const router = inject(Router);

    return applicationService.getUserApplication().pipe(
        last(),
        map(application => {
            if (application?.applicationStatusId == 1) {
                return true;
            }

            else {
                router.navigate(['submitted']);
                return false;
            }
        })
    );
};
