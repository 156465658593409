import { Inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { Metadata, Question } from '../shared/question.model';
@Pipe({
    name: 'metadata',
    standalone: true,
})
export class MetadataPipe implements PipeTransform {
    transform(question: Question, key: string): string | undefined {
        const result = question.metadata.find(m => m.key == key)

        return result 
            ? result.value
            : undefined;
    }
}