import { HttpContextToken, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, switchMap } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const INTERNAL_AUTH_REQUEST = new HttpContextToken(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (req.context.get(INTERNAL_AUTH_REQUEST) === true) {
            return next.handle(req);
        }

        return this.authService.getTokens().pipe(
            switchMap(tokens => {
                if (!tokens) {
                    this.router.navigate(['auth', 'login']);
                    return EMPTY;
                }

                const authReq = req.clone({
                  headers: req.headers.set('Authorization', `${tokens.tokenType} ${tokens.accessToken}`)
                });

                return next.handle(authReq);
            })
        );
    }
}