<div *ngIf="question" [ngClass]="{'error': state == 'error'}">
    <div style="display: flex;">
        <div class="radio-label">{{question.label}}</div>
        <div class="formfield" style="flex: 1; margin-right: 24px;">
            <input type="radio" [id]="answerSet.id + '_' + question.id + '-yes'" name="question-{{question.id}}" (change)="displayQuestionSet()" (blur)="saveAnswer()" (focus)="setInputState('clean')"
                [(ngModel)]="value" [value]="'yes'">
            <label [htmlFor]="answerSet.id + '_' + question.id + '-yes'">Yes</label>
            <input type="radio" [id]="answerSet.id + '_' + question.id + '-no'" name="question-{{question.id}}" (change)="displayQuestionSet()" (blur)="saveAnswer()" (focus)="setInputState('clean')"
                [(ngModel)]="value" [value]="'no'">
            <label [htmlFor]="answerSet.id + '_' + question.id + '-no'">No</label>
        </div>
    </div>
    <div *ngIf="state == 'error'" class="error-message">{{question.label}} is a required field.</div>

    <app-question-set *ngIf="questionSetId" [questionSetId]="questionSetId"></app-question-set>
</div>