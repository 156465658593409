import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthService } from '../../services/auth.service';
import { Login } from '../../models/login.model';
import { Router, RouterModule } from '@angular/router';
import { AuthConfig } from '../../models/auth-config.model';

@Component({
    selector: 'trackomatic-login',
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDividerModule,
        MatCardModule,
        ReactiveFormsModule,
        RouterModule
    ],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent {
    loginForm = new FormGroup({
        emailAddress: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required])
    });

    authConfig: AuthConfig;

    constructor(private authService: AuthService, private router: Router) {
        this.authConfig = this.authService.getConfig();
    }

    login() {
        this.authService.login(this.loginForm.value as Login)
            .subscribe(
                tokens => {
                    const authClaims = this.authService.decode(tokens.accessToken);
                    const roleClaim = authClaims.get('role') as string;

                    if (roleClaim.indexOf('passwordreset') > -1) {
                        this.router.navigate(['auth', 'password']);
                    }

                    else {
                        this.router.navigate(['']);
                    }
                },
                _ => { alert('bad'); }
            );
    }
}
