import { InjectionToken } from "@angular/core";

export const AUTH_CONFIG_DEFAULTS = new InjectionToken<AuthConfig>('authconfig');

export type TokenStorage = "sessionStorage" | "localStorage";

export interface AuthConfig {
    baseUrl: string;
    allowSignup?: boolean;
    tokenStorage?: TokenStorage;
}