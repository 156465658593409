import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Answer, Question } from "./question.model";

@Injectable({
    providedIn: 'root'
})
export class QuestionService {
    question: BehaviorSubject<Question | null> = new BehaviorSubject<Question | null>(null);

    constructor(private http: HttpClient) { }

    saveAnswer(answer: Answer): Observable<any> {
        answer.value = answer.value.toString();
        return this.http.post(`${environment.apiConfig.url}/answers`, answer);
    }
}