import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Attachment } from "./attachment.model";

@Injectable({
    providedIn: 'root'
})
export class AttachmentService {

    constructor(private http: HttpClient) { }

    uploadFile(attachment: Attachment) {
        const formData = new FormData();

        formData.append("file", attachment.file);
        formData.append("attachmentTypeId", attachment.attachmentTypeId);

        return this.http.post(`${environment.apiConfig.url}/attachments/upload`, formData);
    }
}