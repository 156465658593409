import { CommonModule } from "@angular/common";
import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { Question } from "../../shared/question.model";
import { BaseFieldComponent } from "./base-field.component";
import { FormsModule } from "@angular/forms";
import { Subscription } from "rxjs";
import { AttachmentService } from "../../shared/attachment.service";
import { MetadataPipe } from "../../pipes/metadata.pipe";

@Component({
    selector: 'app-attachments-field',
    templateUrl: './attachments-field.component.html',
    styleUrls: ['./formfield.css', './attachments-field.component.css'],
    standalone: true,
    imports: [CommonModule, FormsModule]
})
export class AttachmentsFieldComponent extends BaseFieldComponent implements OnInit, OnDestroy {
    private sub: Subscription = null!;
    error: boolean = false;
    attachments: string[] = [];
    attachmentService = inject(AttachmentService);

    override ngOnInit() {
        super.ngOnInit();

        this.sub = this.formService.shouldValidate.subscribe(shouldValidate => {
            if (shouldValidate) {
                const question = this.question!;
                let invalidQuestions = this.formService.invalidQuestions.value.filter(q => q.id != question.id);

                if (question.required && this.attachments.length === 0) {
                    invalidQuestions.push(question);
                    this.error = true;
                }

                this.formService.invalidQuestions.next(invalidQuestions);
            }
        });
    }

    override ngOnDestroy() {
        super.ngOnDestroy();

        this.sub.unsubscribe();
    }

    uploadFile(event: Event) {
        const attachmentTypeId = new MetadataPipe().transform(this.question, "attachmentTypeId")!;

        const element = event.currentTarget as HTMLInputElement;
        const fileList: FileList | null = element.files;
       
        if (fileList) {
            Array.from(fileList).forEach(file => {
                this.attachmentService.uploadFile({
                    file: file,
                    attachmentTypeId: attachmentTypeId
                }).subscribe(() => {
                    this.attachments.push(file.name);
                });
            });

            this.error = false;
        }
    }

    removeAttachment(name: string) {
        this.attachments = this.attachments.filter(a => a != name);
    }
}