import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, map, Observable, of, tap } from 'rxjs';
import { INTERNAL_AUTH_REQUEST } from '../interceptors/auth.interceptor';
import { AUTH_CONFIG_DEFAULTS, AuthConfig } from '../models/auth-config.model';
import { Login } from '../models/login.model';
import { TokenResponse } from '../models/token-response.model';
import { Signup } from '../models/signup.model';
import { PasswordChange } from '../models/password-change.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    
    constructor(private http: HttpClient, @Inject(AUTH_CONFIG_DEFAULTS) private authConfig: AuthConfig) { }

    getConfig() {
        return this.authConfig;
    }

    signup(signup: Signup): Observable<TokenResponse> {
        return this.http.post<TokenResponse>(`${this.authConfig.baseUrl}`, signup, {
            context: new HttpContext().set(INTERNAL_AUTH_REQUEST, true)
        })
            .pipe(
                tap(response => {
                    this.setTokens(response);
                })
            );
    }

    changePassword(passwordChange: PasswordChange): Observable<TokenResponse> {
        return this.http.put<TokenResponse>(`${this.authConfig.baseUrl}/me/password`, passwordChange);
    }

    login(login: Login): Observable<TokenResponse> {
        return this.http.post<TokenResponse>(`${this.authConfig.baseUrl}/login`, login, {
            context: new HttpContext().set(INTERNAL_AUTH_REQUEST, true)
        })
            .pipe(
                tap(response => {
                    this.setTokens(response);
                })
            );
    }

    loginRefresh(token: string) {
        return this.http.post<TokenResponse>(`${this.authConfig.baseUrl}/login/token`, { refreshToken: token }, {
            context: new HttpContext().set(INTERNAL_AUTH_REQUEST, true)
        })
            .pipe(
                tap(response => {
                    this.setTokens(response);
                })
            );
    }

    logout() {
        // DO NOT SET INTERNAL_AUTH_REQUEST.  TOKEN MUST BE INCLUDED
        return this.http.post(`${this.authConfig.baseUrl}/logout`, null) 
            .pipe(
                tap(() => {
                    if (this.authConfig.tokenStorage === "localStorage") {
                        localStorage.removeItem("tokens");
                    }

                    else {
                        sessionStorage.removeItem("tokens");
                    }
                })
            );
    }


    setTokens(tokens: TokenResponse) {
        const tokenString = JSON.stringify(tokens);

        if (this.authConfig.tokenStorage === "localStorage") {
            localStorage.setItem("tokens", tokenString);
        }

        else {
            sessionStorage.setItem("tokens", tokenString);
        }
    }

    getTokens(): Observable<TokenResponse | null> {
        let tokenString;

        if (this.authConfig.tokenStorage === "localStorage") {
            tokenString = localStorage.getItem("tokens");
        }

        else {
            tokenString = sessionStorage.getItem("tokens");
        }

        if (!tokenString) {
            return of(null);
        }

        const tokens = JSON.parse(tokenString) as TokenResponse;
        const accessTokenClaims = this.decode(tokens.accessToken);
        const now = Math.floor(Date.now() / 1000);

        if (accessTokenClaims.get('exp') as number < now) {
            const refreshTokenClaims = this.decode(tokens.refreshToken);
            if (refreshTokenClaims.get('exp') as number < now) {
                return of(null);
            }

            return this.loginRefresh(tokens.refreshToken);
        }

        return of(tokens);
    }

    getIdTokenClaims(): Observable<Map<string, unknown>> {
        return this.getTokens().pipe(
            map(tokens => {
                if (!tokens) {
                    return new Map<string, unknown>();
                }
                
                return this.decode(tokens.idToken);
            })
        );
    }

    decode(token: string): Map<string, unknown> {
        token = token.split('.')[1];
        token = this.toBase64(token);

        const json = atob(token);
        const claims = JSON.parse(json);
        const claimsMap = new Map<string, unknown>();

        for (const claim in claims) {
            claimsMap.set(claim, claims[claim]);
        }

        return claimsMap;
    }

    private toBase64(input: string) {
        input = input.replace(/-/g, '+')
            .replace(/_/g, '/');

        const padding = input.length % 4;
        if (padding) {
            if (padding === 1) {
                throw new Error('Input is not valid Base64');
            }
            input += new Array(5 - padding).join('=');
        }

        return input;
    }
}
