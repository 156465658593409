import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'rawhtml', pure: false, standalone: true })

export class RawHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(content: any) {
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }
}