<ng-container [ngSwitch]="question.questionType.name">
    <app-electronic-signature *ngSwitchCase="'ElectronicSignature'" [question]="question" [answerSet]="answerSet"></app-electronic-signature>
    <app-date-field *ngSwitchCase="'Date'" [question]="question" [answerSet]="answerSet"></app-date-field>
    <app-attachments-field *ngSwitchCase="'Attachments'" [question]="question" [answerSet]="answerSet"></app-attachments-field>
    <app-dropdown-field *ngSwitchCase="'Dropdown'" [question]="question" [answerSet]="answerSet"></app-dropdown-field>
    <app-state-dropdown-field *ngSwitchCase="'StateDropdown'" [question]="question" [answerSet]="answerSet"></app-state-dropdown-field>
    <app-yes-no-field *ngSwitchCase="'YesNo'" [question]="question" [answerSet]="answerSet"></app-yes-no-field>
    <app-html-field *ngSwitchCase="'HTML'" [question]="question" [answerSet]="answerSet"></app-html-field>
    <app-checkbox-field *ngSwitchCase="'Checkbox'"[question]="question" [answerSet]="answerSet"></app-checkbox-field>
    <app-textarea-field *ngSwitchCase="'Textarea'" [question]="question" [answerSet]="answerSet"></app-textarea-field>
    <app-text-field *ngSwitchDefault [question]="question" [answerSet]="answerSet"></app-text-field>
</ng-container>