<mat-card *ngIf="formResponse">
    <mat-card-content>
        <app-form-renderer [form]="formResponse.form"></app-form-renderer>

        <div class="actions">
            <button type="button" (click)="previousForm()" *ngIf="formResponse.previousId">Back</button>
            <button type="submit" class="primary" (click)="nextForm()">{{ formResponse.nextId ? 'Next' : 'Submit'
                }}</button>
        </div>
    </mat-card-content>
</mat-card>