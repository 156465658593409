import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MetadataPipe } from "../pipes/metadata.pipe";
import { Form } from "../shared/form.model";
import { QuestionService } from "../shared/question.service";
import { RawHtmlPipe } from "../shared/rawhtml.pipe";
import { QuestionSetComponent } from "./question-set.component";

@Component({
    selector: 'app-form-renderer',
    templateUrl: './form-renderer.component.html',
    styleUrls: ['./form-renderer.component.css'],
    standalone: true,
    imports: [CommonModule, FormsModule, RawHtmlPipe, QuestionSetComponent, MetadataPipe, FormRendererComponent],
    providers: [QuestionService]
})
export class FormRendererComponent {
    @Input() form: Form | undefined;
}