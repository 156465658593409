import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { last, map } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
    const authService = inject(AuthService);
    const router =  inject(Router);

    return authService.getTokens().pipe(
        last(),
        map(tokens => {
            if (!tokens) {
                router.navigate(['auth', 'login']);
                return false;
            }

            return true;
        })
    );
}