import { Routes } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";
import { SignupComponent } from "./components/signup/signup.component";
import { signupGuard } from "./guards/signup.guard";
import { LogoutComponent } from "./components/logout/logout.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { authGuard } from "./guards/auth.guard";

export const authRoutes: Routes = [
    {
        path: 'auth',
        data: {
            hasLayout: false
        },
        children: [
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'logout',
                component: LogoutComponent
            },
            {
                path: 'password',
                component: ChangePasswordComponent,
                canActivate: [authGuard]
            },
            {
                path: 'signup',
                component: SignupComponent,
                canActivate: [signupGuard]
            }
        ]
    },
]