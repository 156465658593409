<div *ngIf="questionSet">
    <div id="QuestionSet-{{questionSet.id}}">
        <div *ngFor="let answerSet of questionSet.answerSets; index as i" class="form-container">
            <div *ngIf="questionSet.canDuplicate && questionSet.answerSets.length > 1" style="width: 100%;">
                <button (click)="removeAnswerSet(answerSet.id)">Remove</button>
            </div>
            <div *ngFor="let question of questionSet.questions" class="question-container"
                [style]="(question | metadata:'style') ?? 'width: 100%;'">
                <app-question-factory [question]="question" [answerSet]="answerSet"></app-question-factory>
            </div>
            <hr *ngIf="questionSet.canDuplicate && questionSet.answerSets.length > 1" style="height:2px; width: 100%; background-color: #000; margin-bottom: 24px;">
        </div>

        <button *ngIf="questionSet.canDuplicate" type="button" class="primary" style="margin-bottom: 36px;"
            (click)="addAnswerSet(questionSet.id)">Add Another</button>
    </div>
</div>