import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BaseFieldComponent } from "./base-field.component";
import { RawHtmlPipe } from "../../shared/rawhtml.pipe";

@Component({
    selector: 'app-html-field',
    templateUrl: './html-field.component.html',
    standalone: true,
    imports: [CommonModule, FormsModule, RawHtmlPipe]
})
export class HtmlFieldComponent extends BaseFieldComponent { }