<div class="layout-container">
    <img src="https://riskaware.com/wp-content/themes/rasite/images/logo.png" title="RiskAware, LLC"
        alt="RiskAware, LLC">
    <mat-card>
        <mat-card-content>
            <h3>Sign Up</h3>
            <form [formGroup]="signupForm" (ngSubmit)="signup()">
                <mat-form-field appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="firstName">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="lastName">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Email Address</mat-label>
                    <input matInput formControlName="emailAddress">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" type="password">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Verify Password</mat-label>
                    <input matInput formControlName="verifyPassword" type="password">
                </mat-form-field>

                <button type="submit" mat-flat-button>Sign Up</button>
            </form>

            <mat-divider></mat-divider>

            <div>
                <strong>Already have an account?</strong>
                <a [routerLink]="['/auth', 'login']">Log in</a>
            </div>
        </mat-card-content>
    </mat-card>
</div>