import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'split',
    standalone: true,
})
export class SplitPipe implements PipeTransform {
    transform(input: unknown, separator: string): string[] {
        return input
            ? input.toString().split(separator)
            : [];
    }
}