@if (hasLayout === false) {
<div class="standalone-layout">
    <router-outlet />
</div>
}

@else {
<app-layout>
    <router-outlet />
</app-layout>
}