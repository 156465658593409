import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BaseFieldComponent } from "./base-field.component";
import { MetadataPipe } from "../../pipes/metadata.pipe";
import { ParseJsonPipe } from "../../pipes/parse-json.pipe";
import { SplitPipe } from "../../pipes/split.pipe";

@Component({
    selector: 'app-dropdown-field',
    templateUrl: './dropdown-field.component.html',
    styleUrls: ['./formfield.css'],
    standalone: true,
    imports: [CommonModule, FormsModule, MetadataPipe, ParseJsonPipe, SplitPipe]
})
export class DropdownFieldComponent extends BaseFieldComponent {
    
}