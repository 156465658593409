import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Subscription } from "rxjs";
import { FormService } from "../shared/form.service";
import { AnswerSet, Question } from "../shared/question.model";
import { QuestionService } from "../shared/question.service";
import { AttachmentsFieldComponent } from "./fields/attachments-field.component";
import { DateFieldComponent } from "./fields/date-field.component";
import { DropdownFieldComponent } from "./fields/dropdown-field.component";
import { ElectronicSignatureComponent } from "./fields/electronic-signature.component";
import { StateDropdownFieldComponent } from "./fields/state-dropdown-field.component";
import { TextFieldComponent } from "./fields/text-field.component";
import { HtmlFieldComponent } from "./fields/html-field.component";
import { CheckboxFieldComponent } from "./fields/checkbox-field.component";
import { YesNoFieldComponent } from "./fields/yes-no-field.component";
import { TextareaFieldComponent } from "./fields/textarea-field.component";

@Component({
    selector: 'app-question-factory',
    templateUrl: './question-factory.component.html',
    standalone: true,
    imports: [CommonModule, FormsModule, TextFieldComponent, TextareaFieldComponent, CheckboxFieldComponent, HtmlFieldComponent, ElectronicSignatureComponent, DateFieldComponent, AttachmentsFieldComponent, YesNoFieldComponent,  DropdownFieldComponent, StateDropdownFieldComponent],
    providers: [QuestionService]
})
export class QuestionFactoryComponent {
    @Input() question: Question = null!;
    @Input() answerSet: AnswerSet = null!;
}