<div class="layout-container">
    <img src="https://riskaware.com/wp-content/themes/rasite/images/logo.png" title="RiskAware, LLC"
        alt="RiskAware, LLC">
    <mat-card>
        <mat-card-content>
            <h3>Change Password</h3>
            <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" type="password">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>New Password</mat-label>
                    <input matInput formControlName="newPassword" type="password">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Verify Password</mat-label>
                    <input matInput formControlName="verifyPassword" type="password">
                </mat-form-field>

                <button type="submit" mat-flat-button>Change Password</button>
                <a mat-button (click)="cancel()">Cancel</a>
            </form>
        </mat-card-content>
    </mat-card>
</div>