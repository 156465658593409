import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { authRoutes } from 'auth';
import { isIncompleteGuard } from '../guards/is-incomplete.guard';
import { SubmittedComponent } from './submitted/submitted.component';

export const routes: Routes = [
    ...authRoutes,
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'form/23',
    },
    {
        path: 'form/:id',
        component: HomeComponent,
        canActivate: [isIncompleteGuard]
    },
    {
        path: 'submitted',
        component: SubmittedComponent
    }
];
