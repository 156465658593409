import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BaseFieldComponent } from "./base-field.component";

@Component({
    selector: 'app-state-dropdown-field',
    templateUrl: './state-dropdown-field.component.html',
    styleUrls: ['./formfield.css'],
    standalone: true,
    imports: [CommonModule, FormsModule]
})
export class StateDropdownFieldComponent extends BaseFieldComponent {
    
}