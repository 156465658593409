import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideNoopAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { AUTH_CONFIG_DEFAULTS, AuthInterceptor } from 'auth';
import { routes } from './app.routes';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        importProvidersFrom(BrowserModule),
        provideNoopAnimations(),
        provideHttpClient(withInterceptorsFromDi(), withFetch()),
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, 
            useValue: {
                duration: 10000,
                horizontalPosition: "center",
                verticalPosition: "bottom"
            }
        },
        {
            provide: AUTH_CONFIG_DEFAULTS,
            useValue: {
                baseUrl: 'http://localhost:5034/users',
                allowSignup: true
            }
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ]
};
